import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Hero from "../../components/hero";
import CallNowBanner from "../../components/callNowBanner";
import AreaMap from "../../components/areaMap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import LogoSchema from "../../components/Schema/logo";
import CallIcon from "../../components/call-icon";
import Layout from "../../components/layout";
import { checkPropertiesForNull } from "../../utils/object";
import { SafeHtmlParser } from "../../components/safe-html-parser";

const ReplacingLocksNewHome = () => {
	const data = useStaticQuery(
		graphql`
			{
				site {
					siteMetadata {
						siteUrl
					}
				}
				reviewData: wpPage(slug: { eq: "home" }) {
					reviewCountFields {
						googleReviewCount
					}
				}
				reviews {
					reviewCount
					reviewCount2
				}
				schemaLogo: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
				pageData: wpPage(slug: { eq: "new-home-lock-replacement" }) {
					seoFieldGroups {
						localBusinessSchema
						metaDescription
						metaTitle
						openGraphDescription
						openGraphTitle
						productSchema
						image {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
					newHomeLockReplacementPageFieldGroup {
						newHomeLockBannerSection {
							heading
							description
							cta {
								target
								title
								url
							}
							actions {
								heading
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						newHomeLockCtaSection1 {
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
						}
						newHomeLockCtaSection2 {
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
						}
						newHomeLockGoogleMapSection {
							heading
							googleMap
						}
						newHomeLockTextSection1 {
							description
							heading
						}
						newHomeLockTextSection2 {
							description
							heading
						}
						newHomeLockTextSection3 {
							description
							heading
						}
						newHomeLockTextSection4 {
							description
							heading
						}
						newHomeLockTextSection5 {
							description
							heading
						}
						newHomeLockTextSection6 {
							description
							heading
						}
						newHomeLockTwoColumnSection1 {
							description
							heading
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						newHomeLockTwoColumnSection2 {
							description
							heading
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const {
		pageData: { seoFieldGroups, newHomeLockReplacementPageFieldGroup },
		schemaLogo,
		reviewData,
		site,
		reviews,
	} = data;

	const {
		newHomeLockBannerSection,
		newHomeLockCtaSection1,
		newHomeLockCtaSection2,
		newHomeLockGoogleMapSection,
		newHomeLockTextSection1,
		newHomeLockTextSection2,
		newHomeLockTextSection3,
		newHomeLockTextSection4,
		newHomeLockTextSection5,
		newHomeLockTextSection6,
		newHomeLockTwoColumnSection1,
		newHomeLockTwoColumnSection2,
	} = newHomeLockReplacementPageFieldGroup;

	const siteUrl = site.siteMetadata.siteUrl;

	const reviewCount =
		reviews.reviewCount2 + reviewData.reviewCountFields.googleReviewCount;
	const schemaLogoUrl = schemaLogo.publicURL;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}/what-we-do/new-home-lock-replacement`,
		image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
		mpn: `${siteUrl}/what-we-do/new-home-lock-replacement`,
		sku: `${siteUrl}/what-we-do/new-home-lock-replacement`,
		description: `${seoFieldGroups?.productSchema}`,
		logo: `${siteUrl}${schemaLogoUrl}`,
		name: "Seymour Locksmiths",
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${schemaLogoUrl}`,
			image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
			name: "Seymour Locksmiths",
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/our-locksmith-reviews`,
			datePublished: "2022-01-08T17:58:29+00:00",
			reviewBody:
				"What a fantastic service. I snapped the key in a lock that had been faulty for a while. Jeff arrived quickly and replaced the lock with plenty of spare keys and at a reasonable price. Nice friendly chap. I would definitely recommend and use again in the future. Thank you!",
			author: {
				"@type": "Person",
				name: "Francesca Richards",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Seymour Locksmiths",
				sameAs: `${siteUrl}`,
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Seymour Locksmiths",
				item: "https://www.seymour-locksmiths.co.uk",
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Services",
				item: "https://www.seymour-locksmiths.co.uk/what-we-do",
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "New Home Lock Replacement",
				item: "https://www.seymour-locksmiths.co.uk/what-we-do/new-home-lock-replacement",
			},
		],
	};

	return (
		<Layout>
			<CallIcon />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{seoFieldGroups?.productSchema?.length > 0 && (
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(serviceSchema)}
					</script>
				</Helmet>
			)}

			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/what-we-do/new-home-lock-replacement`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@jefflocksmith",
					site: "@jefflocksmith",
					cardType: "summary",
				}}
			/>

			{newHomeLockBannerSection &&
				!checkPropertiesForNull(newHomeLockBannerSection, ["heading"]) && (
					<Hero
						Tag="section"
						text={
							<SafeHtmlParser
								htmlContent={newHomeLockBannerSection?.description}
							/>
						}
						image={
							newHomeLockBannerSection?.backgroundImage.localFile
								.childImageSharp.gatsbyImageData
						}
						heading={newHomeLockBannerSection?.heading}
						hFontSize="text-4xl"
						button={newHomeLockBannerSection?.cta}
						actions={newHomeLockBannerSection?.actions}
					/>
				)}

			{newHomeLockTextSection1 &&
				!checkPropertiesForNull(newHomeLockTextSection1, ["heading"]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<Row className="mb-5">
									<Col
										className="d-flex justify-content-center text-center"
										xs={12}
									>
										<h2 className="text-3xl mb-3 fw-700 lato">
											{newHomeLockTextSection1?.heading}
										</h2>
									</Col>
									<Col className="d-flex justify-content-center" xs={12}>
										<span className="sl-underline d-block" />
									</Col>
								</Row>
								{newHomeLockTextSection1?.description && (
									<SafeHtmlParser
										htmlContent={newHomeLockTextSection1?.description}
									/>
								)}
							</Col>
						</Row>
					</Container>
				)}
			{newHomeLockTextSection2 &&
				!checkPropertiesForNull(newHomeLockTextSection2, ["heading"]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<Row className="mb-5">
									<Col
										className="d-flex justify-content-center text-center"
										xs={12}
									>
										<h2 className="text-3xl mb-3 fw-700 lato">
											{newHomeLockTextSection2?.heading}
										</h2>
									</Col>
									<Col className="d-flex justify-content-center" xs={12}>
										<span className="sl-underline d-block" />
									</Col>
								</Row>
								{newHomeLockTextSection2?.description && (
									<SafeHtmlParser
										htmlContent={newHomeLockTextSection2?.description}
									/>
								)}
							</Col>
						</Row>
					</Container>
				)}
			{newHomeLockTextSection3 &&
				!checkPropertiesForNull(newHomeLockTextSection3, ["heading"]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<Row className="mb-5">
									<Col
										className="d-flex justify-content-center text-center"
										xs={12}
									>
										<h2 className="text-3xl mb-3 fw-700 lato">
											{newHomeLockTextSection3?.heading}
										</h2>
									</Col>
									<Col className="d-flex justify-content-center" xs={12}>
										<span className="sl-underline d-block" />
									</Col>
								</Row>
								{newHomeLockTextSection3?.description && (
									<SafeHtmlParser
										htmlContent={newHomeLockTextSection3?.description}
									/>
								)}
							</Col>
						</Row>
					</Container>
				)}
			{newHomeLockTextSection4 &&
				!checkPropertiesForNull(newHomeLockTextSection4, ["heading"]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<Row className="mb-5">
									<Col
										className="d-flex justify-content-center text-center"
										xs={12}
									>
										<h2 className="text-3xl mb-3 fw-700 lato">
											{newHomeLockTextSection4?.heading}
										</h2>
									</Col>
									<Col className="d-flex justify-content-center" xs={12}>
										<span className="sl-underline d-block" />
									</Col>
								</Row>
								{newHomeLockTextSection4?.description && (
									<SafeHtmlParser
										htmlContent={newHomeLockTextSection4?.description}
									/>
								)}
							</Col>
						</Row>
					</Container>
				)}
			{newHomeLockTextSection5 &&
				!checkPropertiesForNull(newHomeLockTextSection5, ["heading"]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<Row className="mb-5">
									<Col
										className="d-flex justify-content-center text-center"
										xs={12}
									>
										<h2 className="text-3xl mb-3 fw-700 lato">
											{newHomeLockTextSection5?.heading}
										</h2>
									</Col>
									<Col className="d-flex justify-content-center" xs={12}>
										<span className="sl-underline d-block" />
									</Col>
								</Row>
								{newHomeLockTextSection5?.description && (
									<SafeHtmlParser
										htmlContent={newHomeLockTextSection5?.description}
									/>
								)}
							</Col>
						</Row>
					</Container>
				)}

			{newHomeLockCtaSection1 &&
				!checkPropertiesForNull(newHomeLockCtaSection1, ["heading"]) && (
					<CallNowBanner
						heading={newHomeLockCtaSection1?.heading}
						button={newHomeLockCtaSection1?.cta1}
						button2={newHomeLockCtaSection1?.cta2}
					/>
				)}
			{newHomeLockTwoColumnSection1 &&
				!checkPropertiesForNull(newHomeLockTwoColumnSection1, ["heading"]) && (
					<Container as="section" fluid={true}>
						<Row>
							<Col xs={12} xl={6} className="my-24 mt-below-xl-0">
								<Row className="justify-content-center">
									<Col xs={11} sm={10} xl={9} className="two-column-content">
										<h2 className="text-3xl mb-3 fw-700 lato">
											{newHomeLockTwoColumnSection1?.heading}
										</h2>
										<span className="sl-underline d-block mb-5" />
										{newHomeLockTwoColumnSection1?.description && (
											<SafeHtmlParser
												htmlContent={newHomeLockTwoColumnSection1?.description}
											/>
										)}
									</Col>
								</Row>
							</Col>
							<Col xs={12} xl={6} className="px-0">
								<GatsbyImage
									image={
										newHomeLockTwoColumnSection1?.image?.localFile
											.childImageSharp.gatsbyImageData
									}
									alt={newHomeLockTwoColumnSection1?.image?.altText}
									className="w-100 h-100"
								/>
							</Col>
						</Row>
					</Container>
				)}
			{newHomeLockTextSection6 &&
				!checkPropertiesForNull(newHomeLockTextSection6, ["heading"]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<Row className="mb-5">
									<Col
										className="d-flex justify-content-center text-center"
										xs={12}
									>
										<h2 className="text-3xl mb-3 fw-700 lato">
											{newHomeLockTextSection6?.heading}
										</h2>
									</Col>
									<Col className="d-flex justify-content-center" xs={12}>
										<span className="sl-underline d-block" />
									</Col>
								</Row>
								{newHomeLockTextSection6.description && (
									<SafeHtmlParser
										htmlContent={newHomeLockTextSection6?.description}
									/>
								)}
							</Col>
						</Row>
					</Container>
				)}
			{newHomeLockTwoColumnSection2 &&
				!checkPropertiesForNull(newHomeLockTwoColumnSection2, ["heading"]) && (
					<Container as="section" fluid={true}>
						<Row>
							<Col xs={12} xl={6} className="my-24 mt-below-xl-0">
								<Row className="justify-content-center">
									<Col xs={11} sm={10} xl={9} className="two-column-content">
										<h2 className="text-3xl mb-3 fw-700 lato">
											{newHomeLockTwoColumnSection2?.heading}
										</h2>
										<span className="sl-underline d-block mb-5" />
										{newHomeLockTwoColumnSection2?.description && (
											<SafeHtmlParser
												htmlContent={newHomeLockTwoColumnSection2?.description}
											/>
										)}
									</Col>
								</Row>
							</Col>
							<Col xs={12} xl={6} className="px-0">
								<GatsbyImage
									image={
										newHomeLockTwoColumnSection2?.image?.localFile
											.childImageSharp.gatsbyImageData
									}
									alt={newHomeLockTwoColumnSection2?.image?.altText}
									className="w-100 h-100"
								/>
							</Col>
						</Row>
					</Container>
				)}
			{newHomeLockCtaSection2 &&
				!checkPropertiesForNull(newHomeLockCtaSection2, ["heading"]) && (
					<CallNowBanner
						heading={newHomeLockCtaSection2?.heading}
						button={newHomeLockCtaSection2?.cta1}
						button2={newHomeLockCtaSection2?.cta2}
					/>
				)}

			{newHomeLockGoogleMapSection &&
				!checkPropertiesForNull(newHomeLockGoogleMapSection, ["heading"]) && (
					<AreaMap
						heading={newHomeLockGoogleMapSection?.heading}
						map={newHomeLockGoogleMapSection?.googleMap}
					/>
				)}
		</Layout>
	);
};

export default ReplacingLocksNewHome;
